<template>
  <modal name="PopupEditProfile" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header"
    :adaptive="true" :width="800" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Мой профиль</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>

    <form class="content padding pure-g" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading" />

      <div class="pure-u-3-24 form-row txt-c">
        <file-upload class="padding-v" name="file" :post-action="uploadUrl" :multiple="false" :drop="false"
          :headers="authHeaders" input-id="profile_avatar_upload" v-model="userAvatars" ref="upload" v-if="isEditAllowed"
          @input-filter="inputFile">
          <avatar :userid="userId" :url="user.avatarUrl" />
        </file-upload>
        <avatar v-else :userid="userId" :url="user.avatarUrl" class="padding-v" />
      </div>

      <div class="pure-u-21-24">
        <div class="pure-g container">
          <div class="pure-u-1 form-row" v-if="isManageAllowed">
            <label>Пользователь</label>
            <select v-model="userId">
              <option v-for="u in usersDropdown" :key="'user_' + u.id" :value="u.id">
                {{ u.lastName ? u.lastName + ' ' + u.firstName + ' ' + u.middleName : 'Новый пользователь' }}
              </option>
            </select>
          </div>

          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label>Фамилия</label>
            <input v-model="user.lastName" type="text" :disabled="!isEditAllowed" required placeholder="Фамилия" />
          </div>

          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label>Имя</label>
            <input v-model="user.firstName" type="text" :disabled="!isEditAllowed" required placeholder="Имя" />
          </div>

          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label>Отчество</label>
            <input v-model="user.middleName" type="text" :disabled="!isEditAllowed" placeholder="Отчество" />
          </div>

          <div class="pure-u-1"></div>
          <div class="pure-u-1 pure-u-md-2-3 form-row" v-if="isManageAllowed">
            <label>Роль</label>
            <select v-model="user.roleId" :disabled="user.id == authId">
              <option v-for="r in allRoles" :key="'role_' + r.id" :value="r.id">{{ r.name }}</option>
            </select>
          </div>

          <div class="pure-u-1 pure-u-md-1-3 form-row" v-if="isManageAllowed">
            <label>Специализация</label>
            <select v-model="user.specializationId">
              <option v-for="s in allSpecializations" :key="'specialization_' + s.id" :value="s.id">{{ s.name }}</option>
            </select>
          </div>
        
          <div class="pure-u-1"></div>
          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label>Должность</label>
            <input v-model="user.jobTitle" type="text" :disabled="!isEditAllowed" placeholder="Должность" />
          </div>


          <b class="pure-u-1 block margin-t">Контактная информация</b>
          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label><i class="fal fa-at"></i> Email</label>
            <input v-model="user.email" type="text" :disabled="!isEditAllowed" placeholder="Email" />
          </div>
          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label><i class="fal fa-phone-alt"></i> Телефон</label>
            <input v-model="user.phone" type="text" :disabled="!isEditAllowed" placeholder="Телефон" />
          </div>
          <div class="pure-u-1 pure-u-md-1-3 form-row">
            <label><i class="fab fa-skype text-blue-skype"></i> Skype</label>
            <input v-model="user.skype" type="text" :disabled="!isEditAllowed" placeholder="Skype" />
          </div>
          <div class="pure-u-1 form-row">
            <label>
              <input v-model="user.emailNotifications" type="checkbox" :disabled="!isEditAllowed" />
              Отправлять уведомления на email
            </label>
          </div>

          <b class="pure-u-1 block margin-t">Безопасность</b>
          <div class="pure-u-1 pure-u-md-1-3 form-row" :class="{ error: errors.password }">
            <label>Пароль</label>
            <input v-model="user.newPassword" type="password" :disabled="!isEditAllowed" placeholder="Пароль" />
          </div>
          <div class="pure-u-1 pure-u-md-1-3 form-row" :class="{ error: errors.passwordRepeat }">
            <label>Повторите пароль</label>
            <input v-model="passwordRepeat" type="password" :disabled="!isEditAllowed" placeholder="Повторите пароль" />
          </div>

          <label class="pure-u-1 error" v-if="showErrors && user.newPassword != passwordRepeat">Пароли не
            совпадают</label>
          <label class="pure-u-1 error"
            v-else-if="showErrors && user.newPassword && user.newPassword.length < minPasswordLength">Слишком короткий
            пароль.
            Минимальная длина 6 символов</label>
          <span class="pure-u-1 margin-b hint">Если хотите изменить пароль, то заполните эти поля. Минимальная длина
            пароля - {{ minPasswordLength }} символов</span>

          <div class="pure-u-1" v-if="isManageAllowed">
            <a href="javascript:" class="dashed" @click="showPermissions = !showPermissions">Редактировать разрешения для заданного пользователя</a>
            <div v-if="showPermissions">
              <p class="txt-r">
                <a href="javascript:" class="btn" @click="user.permissions
                = []" v-show="user.permissions.length">
                  Сбросить на доступы роли
                </a>
              </p>
              <permissions-list :selected="userPermissions" @change="user.permissions = $event.selected" />
            </div>
          </div>
        </div>
      </div>
      <div class="pure-u-1-2 form-row">
        <a @click.stop="logout" class="btn btn-destruct">Выйти <i class="fal fa-sign-out margin-l"></i></a>
      </div>
      <div class="pure-u-1-2 form-row txt-r">
        <button type="submit" class="btn btn-primary" v-if="isEditAllowed"><i class="fal fa-check margin-r"></i>
          Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped></style>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { ROLES_REQUEST, SPECIALIZATIONS_REQUEST } from "@/store/actions/roles"
import { PERMISSIONS_REQUEST } from "@/store/actions/auth"

import { apiCall, apiAuthHeaders, API_PREFIX } from "@/utils/api"
import { EDIT_OWN_PROFILE, MANAGE_USERS } from "@/utils/permissions"


import VueElementLoading from 'vue-element-loading'
import FileUpload from 'vue-upload-component'
import Avatar from '@/components/Avatar.vue';
import PermissionsList from '@/components/PermissionsList.vue'

export default {
  name: 'PopupEditProfile',
  components: {
    VueElementLoading,
    FileUpload,
    Avatar,
    PermissionsList
  },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      showErrors: false,
      showPermissions: false,
      // нужен массив все-таки для этого плагина, но по сути там максимум один элемент
      userAvatars: [],
      userId: 0,
      user: {},
      errors: {},
      passwordRepeat: '',
      minPasswordLength: 6
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "allUsers", "allUsersKeyed", "allRoles", "allSpecializations"]),
    authHeaders() { return apiAuthHeaders() },
    uploadUrl() {
      return API_PREFIX + 'users/' + this.userId + '/upload'
    },
    isEditAllowed() {
      return this.authRoles.indexOf(EDIT_OWN_PROFILE) !== -1
    },
    isManageAllowed() {
      return this.authRoles.indexOf(MANAGE_USERS) !== -1
    },
    userPermissions() {
      if (this.user.permissions && this.user.permissions.length) {
        return this.user.permissions
      }
      var role = this.allRoles.find(r => r.id == this.user.roleId)
      return role.values
      //return [...new Set([...role.values, ...this.user.permissions])];
    },
    emptyUser() {
      return {
        id: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        newPassword: '',
        specializationId: '',
        roleId: '',
        avatarUrl: '',
        email: '',
        phone: '',
        skype: ''
      }
    },
    usersDropdown() {
      return [...this.allUsers].sort(this.sortUsersAlphabetically).concat([{ ...this.emptyUser }])
    }
  },
  watch: {
    userId() {
      let user = this.allUsersKeyed[this.userId]
      if (!user) {
        user = { ...this.emptyUser, specializationId: this.allSpecializations[0].id }
      }
      user.newPassword = ''
      this.$set(this, 'user', user)
    }
  },
  methods: {
    sortUsersAlphabetically(a, b) {
      return a.lastName.localeCompare(b.lastName);
    },
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length - 1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.$store.dispatch(ROLES_REQUEST)
      this.$store.dispatch(SPECIALIZATIONS_REQUEST)

      this.userId = data.params

      this.$escStack.push(this.$options.name)
    },

    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    },

    startUpload(file) {
      this.isUploading = true
      this.$refs.upload.update(file, { active: true })
    },
    // обновление статуса загружаемого файла
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        console.log(newFile)
        this.$nextTick(() => this.startUpload(newFile))
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.$set(this.user, 'avatarUrl', newFile.response.url)
        }
      }
    },
    validate() {
      this.showErrors = false
      if (this.user.newPassword != this.passwordRepeat) {
        this.$set(this.errors, 'password', true)
        this.$set(this.errors, 'passwordRepeat', true)
        this.showErrors = true
      }
      if (this.user.newPassword && this.user.newPassword.length < this.minPasswordLength) {
        this.$set(this.errors, 'password', true)
        this.$set(this.errors, 'passwordRepeat', true)
        this.showErrors = true
      }

      return !this.showErrors
    },
    onSubmit: function () {
      if (!this.validate()) {
        return
      }
      this.isLoading = true
      this.user.permissions = this.userPermissions
      apiCall({ url: 'users/edit', method: "POST", data: this.user })
        .then(resp => {
          console.log(resp)
          this.$modal.hide(this.$options.name)
          this.isLoading = false
          this.$store.dispatch(PERMISSIONS_REQUEST)
        })
    }
  }
}
</script>