<template>
  <div>
    <multiselect
    class="tags-picker"
    v-model="selected"
    track-by="id"
    @open="isDropdownOpened = true"
    @close="isDropdownOpened = false"
    @search-change="asyncFind"
    @input="onChanged"
    :placeholder="placeholder"
    :multiple="true"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    :close-on-select="false"
    :open-direction="position"
    :max-height="maxHeight"
    :disabled="disabled"
    :options="usersList">
      <span slot="noResult">Никого не найдено</span>
      <template slot="option" slot-scope="props">
        <span class="dropdown-option">
          <span class="option-tagged" v-if="props.option.specializationName('') != ''" v-bind:style="{'background-color': props.option.bgColor(), 'color': getTextColor(props.option.bgColor())}">{{ props.option.specializationName('')}}</span>
          {{ props.option.fullName() }}</span>
      </template>
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="tagged" v-bind:style="{'background-color': option.bgColor(), 'color': getTextColor(option.bgColor())}">
          <span>{{ option.specializationName(' - ') }} {{ option.fullName() }}</span>
          <span class="remove-tag" @click="remove(option)">
            <i class="fal fa-times"></i>
          </span>
        </span>
      </template>
    </multiselect>
      <div class="transparent-overlay" v-if="isDropdownOpened" @click.prevent.stop></div>
  </div>
</template>

<style scoped>
.tags-picler {
  z-index: 2;
}
.transparent-overlay {
  position: fixed;
  z-index: 1;
  top: -300%;
  left: -300%;
  width: 600%;
  height: 600%;
  background-color: transparent;
}
</style>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapGetters } from "vuex"
import { USERS_REQUEST } from "@/store/actions/users"

import textColor from '@/utils/textColor'
//import User from '@/models/user';
//import apiCall from "@/utils/api";

export default {
  name: 'UsersPicker',
  components: { Multiselect },
  props: {
    'value': Array,
    'disabled': {
      type: Boolean,
      default: false
    },
    'placeholder': {
      type: String,
      default: "Выберите пользователей"
    },
    'allCustomers': {
      type: Boolean,
      default: false
    },
    'roles': {
			type: Array,
			default: () => []
		},
    'hiddenRoles': {
			type: Array,
			default: () => []
		},
    'ids': {
      type: Array,
      default: () => []
    },
    'position': {
      type: String,
      default: ''
    },
    'maxHeight': {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      isLoading: false,
      isDropdownOpened: false,
      selected: null,
      query: '',
      users: []
    }
  },
  computed: {
    ...mapGetters(["allUsers", "activeProject", "customerRoles"]),
    usersList() {
      let list = this.users
      if (this.activeProject) {
        list = list.filter(u => this.allCustomers || !this.customerRoles.includes(u.roleId) || this.activeProject.customerIds.indexOf(u.id) !== -1)
      }
      if (this.roles && this.roles.length) {
        list = list.filter(u => this.roles.includes(u.roleId))
      }
      if (this.hiddenRoles && this.hiddenRoles.length) {
        list = list.filter(u => this.hiddenRoles.indexOf(u.roleId) === -1)
      }
      if (this.ids && this.ids.length) {
        list = list.filter(u => this.ids.indexOf(u.id) !== -1)
      }
      if (this.query.length) {
        list = list.filter(u => {
          return u.firstName.toLowerCase().indexOf(this.query) !== -1 || 
                u.lastName.toLowerCase().indexOf(this.query) !== -1 ||
                u.middleName.toLowerCase().indexOf(this.query) !== -1
        })
      }
      return list
    }
  },
  watch: {
    allUsers(newVal) {
      this.users = newVal
      if (this.value) {
        this.selected = this.users.filter(p => this.value.indexOf(p.id) !== -1)
      }
      this.isLoading = false
    },
    value() {
      if (this.value) {
        this.selected = this.users.filter(p => this.value.indexOf(p.id) !== -1)
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.$store.dispatch(USERS_REQUEST)
  },
  methods: {
    asyncFind (query) {
      this.query = query.toLowerCase()
      // this.isLoading = true
      /*apiCall({ url: "users?query=" + query, method: "GET" })
      .then(resp => {
        this.updateUsers(resp)
        this.isLoading = false
      })*/
      
      this.isLoading = false
    },
    onChanged(values) {
      this.$emit('input', values.map(p => p.id))
    },
    getTextColor(color) {
      return textColor(color)
    }
  }
}
</script>